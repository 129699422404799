
<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <div class="header-section">
        <h1>{{title}}</h1>
      </div>

      <div class="input-section">
        <button mat-raised-button color="primary" (click)="openDialog()">
          <h2>Click to enter News Source</h2>
        </button>
      </div>

      <div class="validation-result" *ngIf="urlInfo">
        <h3>URL Information</h3>
        <p><strong>URL:</strong> {{ urlInfo.url }}</p>
        <p><strong>Status:</strong> {{ urlInfo.status }}</p>
        <p><strong>Created At:</strong> {{ urlInfo.created_at | date:'medium' }}</p>
        <p><strong>Check Count:</strong> {{ urlInfo.check_count }}</p>
        <p><strong>Probability of being a Fake News website:</strong> {{ urlInfo.fakeProbability }}%</p>
        <p><strong>Short Info:</strong> {{ urlInfo.shortInfo }}</p>
        <p><strong>Name:</strong> {{ urlInfo.name }}</p>
        <p><strong>Established:</strong> {{ urlInfo.established }}</p>
        <p><strong>Number of Awards:</strong> {{ urlInfo.awards }}</p>
        <p><strong>Previous Names:</strong> {{ urlInfo.previousNames }}</p>
        <p><strong>Number of Employees:</strong> {{ urlInfo.employees }}</p>
        <p><strong>Logo:</strong> <img *ngIf="urlInfo.logo !== 'n/a'" [src]="urlInfo.logo" alt="Logo" width="100"></p>
        <p><strong>Political Orientation:</strong> {{ urlInfo.politicalOrientation }}</p>
        <p><strong>Bias Over Years:</strong> {{ urlInfo.biasOverYears }}</p>
        <p><strong>Bias This Year:</strong> {{ urlInfo.biasThisYear }}</p>
        <p><strong>Number of False Reports:</strong> {{ urlInfo.falseReports }}</p>
        <p><strong>Number of Uncovering Reports:</strong> {{ urlInfo.uncoveringReports }}</p>
        <p><strong>Trusted by Other News Sources:</strong> {{ urlInfo.trustedByPercent }}%</p>
        <p><strong>Overall trustability Scoring</strong> {{ urlInfo.trustedByPercent }}%</p>
      </div>
    </div>
  </div>
</div>