<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1 class="title">{{ title }}</h1>

  
      

      <!-- Input Section -->
      <div class="form-container">
        <input #username type="text" placeholder="Telegram Username" />
        <button (click)="getUserInfo(username.value)" [disabled]="isLoading">Get User Info</button>
      </div>

      <div *ngIf="errorMessage" class="error-message">
        <p>{{ errorMessage }}</p>
      </div>

      <!-- Display User Info -->
      <div *ngIf="user" class="profile-section">
        <div class="profile-header">
          <img class="profile-image" [src]="user.avatar || '/default-avatar.png'" alt="User Avatar" />
          <div class="profile-details">
            <div class="profile-name">{{ user.first_name }} {{ user.last_name }}</div>
            <div class="profile-username">{{ user.username }}</div>
          </div>
        </div>

        <div class="additional-info">
          <p><strong>Account Age:</strong> <span class="info-highlight">{{ accountAge }}</span></p>
          <p><strong>Registered Date:</strong> <span class="info-highlight">{{ registeredDate }}</span></p>
          <p><strong>Location:</strong> <span class="info-highlight">{{ location }}</span></p>
        </div>

        <div class="probabilities">
          <p><strong>Fake Account Probability:</strong> <span class="probability-highlight">{{ fakeProbability | number:'1.0-0' }}%</span></p>
        </div>
      </div>

      <!-- Tag Cloud -->
      <div *ngIf="tagCloud.length > 0" class="tag-cloud">
        <h4 class="tag-cloud-title">Tag Cloud</h4>
        <div *ngFor="let tag of tagCloud" class="tag">{{ tag }}</div>
      </div>

      <!-- User Posts -->
      <div *ngIf="posts.length > 0" class="user-posts">
        <h3 class="posts-heading">User Posts</h3>
        <div class="posts-list">
          <div *ngFor="let post of posts" class="post">
            <p class="post-desc">{{ post.description }}</p>
            <p class="post-stats">{{ post.likes }} Likes | {{ post.views }} Views</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
