<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger">
      <app-sidebar-cmp></app-sidebar-cmp>
    </div>
  
    <div class="main-panel">
      <app-navbar-cmp></app-navbar-cmp>
  
      <div class="content">
        <h1 class="title">{{ title }}</h1>

  <div class="truth-social-container">
    <h1>Truth Social User Info</h1>
    <div>
      <input
        type="text"
        placeholder="Enter username"
        #usernameInput
        class="input-box"
      />
      <button (click)="getUserInfo(usernameInput.value)" class="fetch-button">
        Fetch User Info
      </button>
    </div>
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div *ngIf="userInfo" class="user-info">
      <div *ngIf="userInfo.header" class="header-image">
        <img [src]="userInfo.header" alt="Header image" />
      </div>
      <div class="user-details">
        <img
          *ngIf="userInfo.avatar"
          [src]="userInfo.avatar"
          alt="User avatar"
          class="avatar"
        />
        <h2>{{ userInfo.username }}</h2>
        <p class="note" [innerHTML]="userInfo.note"></p>
        <p><strong>Account Created:</strong> {{ registeredDate }}</p>
        <p><strong>Followers:</strong> {{ userInfo.followerCount | number }}</p>
        <p><strong>Total Posts:</strong> {{ userInfo.statusesCount }}</p>
        <p *ngIf="userInfo.website">
          <a [href]="userInfo.website" target="_blank">{{ userInfo.website }}</a>
        </p>
      </div>
    </div>
    <div *ngIf="posts.length > 0">
      <h3>User Posts</h3>
      <ul>
        <li *ngFor="let post of posts">{{ post.content }}</li>
      </ul>
    </div>
    <div *ngIf="tagCloud.length > 0">
      <h3>Tag Cloud</h3>
      <div class="tag-cloud">
        <span *ngFor="let tag of tagCloud">{{ tag }}</span>
      </div>
    </div>
  </div>
    
      </div>
    </div>
