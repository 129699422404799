<div class="sidebar-wrapper">
    <div class="logo">
      <a href="http://canpostthis.com" class="simple-text logo-normal">
        <img src="assets/paper-design/logo.png">
      </a>
    </div>
      <ul class="nav">
        <li class="active">
            <a routerLink="/dashboard" routerLinkActive="active">
                <p>Dashboard</p>
            </a>
        </li>
        
        <li>
          <a routerLink="/account" routerLinkActive="active">
            <p>Your account</p>
        </a>
        </li>
        <li>
          <a routerLink="/account" routerLinkActive="active">
            <p>Account analysis</p>
        </a>
        </li>
        <li>
          <a routerLink="/news-analysis" routerLinkActive="active">News analysis
          </a>
        </li>
        <li>
          <a routerLink="/analysis" routerLinkActive="active">Social Media Profiling
          </a>
        </li>
        <li>
        <li>
          <a routerLink="/youtube" routerLinkActive="active">Youtube
          </a>
        </li>
        <li>
          <a routerLink="/linked-in" routerLinkActive="active">LinkedIn
          </a>
        </li>
        <li>
          <a routerLink="/facebook" routerLinkActive="active">Facebook
          </a>
        </li>
        <li>
          <a routerLink="/truth-social" routerLinkActive="active">Truth Social
          </a>
        </li>
        <li>
          <a routerLink="/telegram" routerLinkActive="active">Telegram
          </a>
        </li>
        <li>
          <a routerLink="/snap" routerLinkActive="active">SnapChat
          </a>
        </li>
        <li>
          <a routerLink="/x-comp" routerLinkActive="active">X (formerly Twitter)
          </a>
        </li>
        <li>
          <a routerLink="/instagram" routerLinkActive="active">Instagram</a>
        </li>
        <li>
        <a routerLink="/tiktok" routerLinkActive="active">TikTok</a>
      </li>
      <li>
        <a routerLink="/payments" routerLinkActive="active">Payments</a>
      </li>
        <li>  
          <a routerLink="/logout" routerLinkActive="active">Logout</a>
        </li>
      
      </ul>
  </div>
  
