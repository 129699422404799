import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  private apiUrl = 'https://twitter154.p.rapidapi.com/user/details?username=';
  private postsUrl = 'https://twitter154.p.rapidapi.com/user/posts?user_id=';
  private readonly storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount';

  constructor(private http: HttpClient) {}

  /**
   * Fetches user details for a given username and stores the account in the database.
   * @param username - The Twitter username.
   */
  getUser(username: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-rapidapi-host': 'twitter154.p.rapidapi.com',
      'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'
    });

    return this.http.get<any>(`${this.apiUrl}${username}`, { headers }).pipe(
      tap((userData) => {
        // Store the user data in the database after fetching the data
        const account = {
          service: 'twitter',
          data: userData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Fetches user posts for a given user ID and stores the account in the database.
   * @param userId - The Twitter user ID.
   */
  getUserPosts(userId: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-rapidapi-host': 'twitter154.p.rapidapi.com',
      'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'
    });

    return this.http.get<any>(`${this.postsUrl}${userId}`, { headers }).pipe(
      tap((userPosts) => {
        // Store the user posts in the database after fetching the data
        const account = {
          service: 'twitter',
          data: userPosts,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Stores a Twitter account in the database.
   * @param account - Account data to be stored.
   */
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }
}
