import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TwitterService } from '../services/twitter.service';

@Component({
  selector: 'app-x',
  templateUrl: './x.component.html',
  styleUrls: ['./x.component.scss'],
})
export class XComponent implements OnInit {
  errorMessage: string | null = null;
  user: any = null;
  fakeProbability: number = 0;
  probabilityMessage: string = '';
  accountAge: string = '';
  registeredDate: string = '';
  posts: any[] = [];
  tagCloud: string[] = [];
  title = 'X';

  constructor(private twitterService: TwitterService) {}

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    if (!username) {
      this.errorMessage = 'Username is required!';
      return;
    }

    this.twitterService.getUser(username).subscribe(
      (response) => {
        if (response) {
          this.user = response;
          this.errorMessage = null;

          this.calculateAccountDetails();
          this.calculateFakeProbability();

          if (this.user.id) {
            this.getUserPosts(this.user.id);
          } else {
            console.error('User ID is missing in the API response.');
          }
        } else {
          this.errorMessage = 'Twitter user data not found in the API response.';
          this.user = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching Twitter profile: ${error.message}`;
        console.error('Twitter API error:', error);
      }
    );
  }

  calculateAccountDetails(): void {
    if (this.user.created_at) {
      const createDate = new Date(this.user.created_at);
      this.registeredDate = createDate.toDateString();
      const ageInMs = new Date().getTime() - createDate.getTime();
      this.accountAge = `${Math.floor(ageInMs / (365 * 24 * 60 * 60 * 1000))} years`;
    }
  }

  calculateFakeProbability(): void {
    if (!this.user) {
      console.error('User is undefined in calculateFakeProbability');
      return;
    }

    let probability = 0;
    let usedCriteria = '';

    // Check for numbers in username
    if (this.user.username && (this.user.username.match(/\d/g) || []).length > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }

    // Check account age
    if (
      this.user.created_at &&
      new Date().getTime() - new Date(this.user.created_at).getTime() < 365 * 24 * 60 * 60 * 1000
    ) {
      probability += 50;
      usedCriteria += 'Account age is less than 1 year. ';
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.username} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
    console.log(this.probabilityMessage);
  }

  getUserPosts(userId: string): void {
    this.twitterService.getUserPosts(userId).subscribe(
      (data) => {
        this.posts = data;
        this.generateTagCloud();
      },
      (error) => console.error(error)
    );
  }

  generateTagCloud(): void {
    const keywords: { [key: string]: number } = {};

    this.posts.forEach((post) => {
      const words = post.text.split(' ');
      words.forEach((word: string) => {
        if (word.startsWith('#')) {
          keywords[word] = (keywords[word] || 0) + 1;
        }
      });
    });

    this.tagCloud = Object.keys(keywords).sort((a, b) => keywords[b] - keywords[a]).slice(0, 10);
  }
}
