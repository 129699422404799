import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TikTokService {
  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your key
  private apiHost = 'tiktok-api23.p.rapidapi.com';
  private readonly storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount';

  constructor(private http: HttpClient) {}

  /**
   * Fetches TikTok profile for a given username and stores the account in the database.
   * @param username - The TikTok username.
   */
  getTikTokProfile(username: string): Observable<any> {
    const url = `https://${this.apiHost}/api/user/info?uniqueId=${username}`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });

    return this.http.get(url, { headers }).pipe(
      tap((profileData) => {
        // Store the profile data in the database after fetching
        const account = {
          service: 'tiktok',
          data: profileData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Fetches TikTok user posts for a given secUid and stores the account in the database.
   * @param secUid - The TikTok user ID (secUid).
   */
  getUserPosts(secUid: string): Observable<any> {
    const url = `https://${this.apiHost}/api/user/posts?secUid=${secUid}&count=35&cursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });

    return this.http.get(url, { headers }).pipe(
      tap((postsData) => {
        // Store the posts data in the database after fetching
        const account = {
          service: 'tiktok',
          data: postsData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Fetches TikTok user followers for a given secUid and stores the account in the database.
   * @param secUid - The TikTok user ID (secUid).
   */
  getUserFollowers(secUid: string, count = 50): Observable<any> {
    const url = `https://${this.apiHost}/api/user/followers?secUid=${secUid}&count=${count}&minCursor=0&maxCursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });

    return this.http.get(url, { headers }).pipe(
      tap((followersData) => {
        // Store the followers data in the database after fetching
        const account = {
          service: 'tiktok',
          data: followersData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Fetches TikTok user followings for a given secUid and stores the account in the database.
   * @param secUid - The TikTok user ID (secUid).
   */
  getUserFollowings(secUid: string, count = 50): Observable<any> {
    const url = `https://${this.apiHost}/api/user/followings?secUid=${secUid}&count=${count}&minCursor=0&maxCursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });

    return this.http.get(url, { headers }).pipe(
      tap((followingsData) => {
        // Store the followings data in the database after fetching
        const account = {
          service: 'tiktok',
          data: followingsData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  /**
   * Stores a TikTok account in the database.
   * @param account - Account data to be stored.
   */
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }
}
