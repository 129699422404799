import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'login-cmp',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  showSuccess = false;
  msg = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    private http: HttpClient
  ) {
    this.loginForm = this.formBuilder.group({
      inputUserName: ['', [Validators.required]],
      inputPassword: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      if (code) {
        this.sendCodeToServer(code);
      }
    });
  }

  login(): void {
    this.authenticationService
      .login(this.f.inputUserName.value, this.f.inputPassword.value)
      .pipe(first())
      .subscribe(
        user => {
          this.router.navigate(['/dashboard']);
        },
        error => {
          this.msg = error.error.message || 'Failed to login';
        }
      );
  }

  get f() {
    return this.loginForm.controls;
  }

  loginWithInstagram(): void {
    const clientId = '780134460535976';
    const redirectUri = encodeURIComponent('http://www.dashboard.canpostthis.com/#/dashboard');
    const responseType = 'code';
    const scope = 'user_profile,user_media';
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}`;
    window.location.href = authUrl;
  }

  sendCodeToServer(code: string): void {
    this.http.post(`${this.authenticationService.apiUrl}/exchange-code`, { code: code }, {
      withCredentials: true
    }).subscribe(
      (data: any) => {
        this.authenticationService.setSession(data.token); 
        this.router.navigate(['/dashboard']);
      },
      error => {
        console.error('There was an error exchanging the code', error);
      }
    );
  }
}
