import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TikTokService } from '../services/tiktok.service';

@Component({
  selector: 'app-tik-tok',
  templateUrl: './tik-tok.component.html',
  styleUrls: ['./tik-tok.component.scss']
})
export class TikTokComponent implements OnInit {
  errorMessage: string | null = null;
  user: any;
  fakeProbability: number = 0;
  probabilityMessage: string = '';
  accountAge: string = '';
  registeredDate: string = '';
  location: string = '';
  probabilityOfWrongLocation: number = -1; // -1 indicates no data yet
  posts: any[] = [];
  tagCloud: string[] = [];
  title = 'TikTok';

  constructor(private tiktokService: TikTokService) {}

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    if (!username) {
      this.errorMessage = 'Username is required!';
      return;
    }

    this.tiktokService.getTikTokProfile(username).subscribe(
      (response) => {
        console.log('TikTok API response:', response);
        if (response && response.userInfo && response.userInfo.user) {
          this.user = response.userInfo.user;
          this.errorMessage = null;

          const stats = response.userInfo.stats;
          if (stats) {
            this.user.followerCount = stats.followerCount;
            this.user.followingCount = stats.followingCount;
          }

          this.calculateAccountDetails();
          this.calculateFakeProbability();

          if (this.user.secUid) {
            this.getTikTokUserPosts(this.user.secUid);
          } else {
            console.error('secUid is missing in the API response.');
          }
        } else {
          this.errorMessage = 'TikTok user data not found in the API response.';
          this.user = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching TikTok profile: ${error.message}`;
        console.error('TikTok API error:', error);
      }
    );
  }

  calculateAccountDetails(): void {
    if (this.user.createTime) {
      const createDate = new Date(this.user.createTime * 1000);
      this.registeredDate = createDate.toDateString();
      const ageInMs = new Date().getTime() - createDate.getTime();
      this.accountAge = `${Math.floor(ageInMs / (365 * 24 * 60 * 60 * 1000))} years`;
    }

    this.location = this.user.region || 'Unknown';
    this.probabilityOfWrongLocation = Math.random() * 50; // Placeholder for real calculation
  }

  calculateFakeProbability(): void {
    if (!this.user) {
      console.error('User is undefined in calculateFakeProbability');
      return;
    }

    let probability = 0;
    let usedCriteria = '';

    if (this.user.uniqueId && (this.user.uniqueId.match(/\d/g) || []).length > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }

    if (this.user.createTime && new Date().getTime() - this.user.createTime * 1000 < 365 * 24 * 60 * 60 * 1000) {
      probability += 50;
      usedCriteria += 'Account age is less than 1 year. ';
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.uniqueId} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
    console.log(this.probabilityMessage);
  }

  getTikTokUserPosts(secUid: string): void {
    this.tiktokService.getUserPosts(secUid).subscribe(
      (data) => {
        this.posts = data.itemList;
        this.generateTagCloud();
      },
      (error) => console.error(error)
    );
  }

  generateTagCloud(): void {
    const keywords: { [key: string]: number } = {};

    this.posts.forEach((post) => {
      const words = post.desc.split(' ');
      words.forEach((word: string) => {
        keywords[word] = (keywords[word] || 0) + 1;
      });
    });

    this.tagCloud = Object.keys(keywords).sort((a, b) => keywords[b] - keywords[a]).slice(0, 10);
  }
}
