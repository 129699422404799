import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LinkedInService } from '../services/linked-in.service';

@Component({
  selector: 'app-linked-in',
  templateUrl: './linked-in.component.html',
  styleUrls: ['./linked-in.component.scss']
})
export class LinkedInComponent {
  errorMessage: string | null = null;
  profiles: any[] = [];

  constructor(private linkedInService: LinkedInService) {}

  getLinkedInProfiles(name: string, jobTitle: string, location: string): void {
    if (!name) {
      this.errorMessage = 'Name is required!';
      return;
    }

    this.linkedInService.fetchProfiles(name, jobTitle, location).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.profiles = response;
          this.errorMessage = null;
        } else {
          this.errorMessage = 'No profiles found matching your criteria.';
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching profiles: ${error.message}`;
        console.error('LinkedIn API error:', error);
      }
    );
  }
}
