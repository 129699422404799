<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger">
      <app-sidebar-cmp></app-sidebar-cmp>
    </div>
  
    <div class="main-panel">
      <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1 class="title">LinkedIn Profile Search</h1>
  
      <div class="form-container">
        <input #name type="text" placeholder="Name" />
        <input #jobTitle type="text" placeholder="Job Title" />
        <input #location type="text" placeholder="Location" />
        <button (click)="getLinkedInProfiles(name.value, jobTitle.value, location.value)">
          Search Profiles
        </button>
      </div>
  
      <div *ngIf="errorMessage" class="error-message">
        <p>{{ errorMessage }}</p>
      </div>
  
      <div *ngIf="profiles.length > 0" class="profile-results">
        <h3>Search Results</h3>
        <div *ngFor="let profile of profiles" class="profile-card">
          <h4>{{ profile.name }}</h4>
          <p><strong>About:</strong> {{ profile.about }}</p>
          <p><strong>Company:</strong> {{ profile.company }}</p>
          <p *ngIf="profile.city"><strong>City:</strong> {{ profile.city }}</p>
          <p *ngIf="profile.company_description">
            <strong>Company Description:</strong> {{ profile.company_description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

