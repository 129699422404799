import { Component, OnInit } from '@angular/core';
import {
  IPayPalConfig,
  ICreateOrderRequest 
} from 'ngx-paypal';



@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss'
})
export class PaymentsComponent implements OnInit {
  
  public payPalConfig?: IPayPalConfig;
  showCancel: boolean;
  showError: boolean;

  ngOnInit(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      clientId: 'sb',
      createOrderOnServer: (data) => fetch('https://37.120.189.106:4999/api/create-paypal-transaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then((res) => res.json())
      .then((order) => order.orderID),
      
      authorizeOnServer: (approveData) => {
        return fetch('https://37.120.189.106:4999/api/authorize-paypal-transaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderID: approveData.orderID
          })
        }).then((res) => res.json())
          .then((details) => {
            alert('Authorization created for ' + details.payer_given_name);
          });
      },

      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.showCancel = true;
      },
      onError: err => {
        console.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        this.resetStatus();
      },
    };
  }
  resetStatus() {
    throw new Error('Method not implemented.');
  }
}
