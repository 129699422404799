<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{ title }}</h1>

      
     

      <div class="search-container">
        <input
          type="text"
          [(ngModel)]="searchQuery"
          placeholder="Enter channel ID"
        />
        <button (click)="searchChannel()">Search</button>
      </div>
      
      <div *ngIf="channelDetails" class="channel-info">
        <h2>{{ channelDetails.title }}</h2>
        <img
          *ngIf="channelDetails.avatar && channelDetails.avatar.length"
          [src]="channelDetails.avatar[2]?.url"
          alt="{{ channelDetails.title }}"
        />
        <p><strong>Description:</strong> {{ channelDetails.description }}</p>
        <p><strong>Subscribers:</strong> {{ channelDetails.subscriber_count }}</p>
      
        <div class="links">
          <h3>Links:</h3>
          <ul *ngIf="channelDetails.links && channelDetails.links.length">
            <li *ngFor="let link of channelDetails.links">
              <a [href]="link.url" target="_blank">{{ link.label }}</a>
            </li>
          </ul>
        </div>
      
        <div class="banner">
          <h3>Banner:</h3>
          <img
            *ngIf="channelDetails.banner && channelDetails.banner.length"
            [src]="channelDetails.banner[0]?.url"
            alt="Channel Banner"
          />
        </div>
      </div>
      
      

      <!-- Error Message -->
      <div *ngIf="errorMessage" class="error">
        <p>{{ errorMessage }}</p>
      </div>

      <!-- Suggestions List -->
      <div *ngIf="suggestions.length > 0">
        <h4>Suggestions</h4>
        <ul>
          <li *ngFor="let suggestion of suggestions">
            {{ suggestion.title }} <!-- Assuming 'title' is a valid property -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
