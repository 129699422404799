import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'; // Import tap for side-effect handling

@Injectable({
  providedIn: 'root'
})
export class LinkedInService {
  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your key
  private apiHost = 'fresh-linkedin-profile-data.p.rapidapi.com';
  private readonly storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount'; // Endpoint to store account data

  constructor(private http: HttpClient) {}

  // Method to fetch LinkedIn profiles based on the search criteria
  fetchProfiles(
    name: string,
    jobTitle: string = '',
    location: string = '',
    limit: number = 5
  ): Observable<any> {
    const url = `https://${this.apiHost}/google-full-profiles`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Content-Type': 'application/json',
    });
    const body = {
      name,
      company_name: '',
      job_title: jobTitle,
      location,
      keywords: '',
      limit,
    };

    return this.http.post(url, body, { headers }).pipe(
      tap((profileData) => {
        // Store the profile data in the database after fetching
        const account = {
          service: 'linkedin',
          data: profileData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  // Method to store account in the database
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }
}
