import { Component, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {
  title = 'Social Media Profiling';

  displayedColumns: string[] = ['platform', 'userName', 'realName', 'age', 'motherTongue', 'probabilities'];
  dataSource = [
    { platform: 'Facebook', userName: 'john_doe', realName: 'John Doe', age: 29, motherTongue: 'English', probabilities: '5%' },
    { platform: 'Twitter', userName: 'jane123', realName: 'Jane Smith', age: 25, motherTongue: 'French', probabilities: '20%' },
    { platform: 'Instagram', userName: 'coolcat', realName: 'Mia Wallace', age: 22, motherTongue: 'Spanish', probabilities: '12%' },
  ];

  fakeAccountProbability = [
    { name: 'Real Account', value: 80 },
    { name: 'Fake Account', value: 20 },
  ];

  topicsOfInterest = [
    { name: 'Technology', value: 40 },
    { name: 'Sports', value: 25 },
    { name: 'Music', value: 35 },
  ];

  trendingTopics = [
    { name: 'Climate Change', value: 50 },
    { name: 'AI Innovations', value: 70 },
    { name: 'World Cup', value: 90 },
  ];

  // Define custom color schemes
  customColorScheme: Color = {
    name: 'customScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  trendingColorScheme: Color = {
    name: 'trendingScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#1f78b4', '#33a02c', '#e31a1c', '#ff7f00'],
  };

  ngOnInit(): void {}
}
