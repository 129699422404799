<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar-cmp></app-sidebar-cmp>
      </div>
    
      <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
    <div class="content">
      <h1 class="title">{{ title }}</h1>
  
      <div class="form-container">
        <input #usernameOrUrl type="text" placeholder="Facebook Username or URL" />
        <button (click)="getFacebookInfo(usernameOrUrl.value)">Get Facebook Info</button>
      </div>
  
      <div *ngIf="errorMessage" class="error-message">
        <p>{{ errorMessage }}</p>
      </div>
  
      <div *ngIf="profile" class="profile-section">
        <h2>Profile Information</h2>
        <p><strong>Name:</strong> {{ profile.title }}</p>
        <p>
          <strong>Profile Link:</strong>
          <a [href]="profile.url" target="_blank">{{ profile.url }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
