<div class="wrapper">

    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar-cmp></app-sidebar-cmp>
    
    </div>
  
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
  
        <div class="content">       
            <h1>{{title}}</h1>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="platform">
                  <th mat-header-cell *matHeaderCellDef> Platform </th>
                  <td mat-cell *matCellDef="let element"> {{ element.platform }} </td>
                </ng-container>
        
                <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef> User Name </th>
                  <td mat-cell *matCellDef="let element"> {{ element.userName }} </td>
                </ng-container>
        
                <ng-container matColumnDef="realName">
                  <th mat-header-cell *matHeaderCellDef> Real Name </th>
                  <td mat-cell *matCellDef="let element"> {{ element.realName }} </td>
                </ng-container>
        
                <ng-container matColumnDef="age">
                  <th mat-header-cell *matHeaderCellDef> Age </th>
                  <td mat-cell *matCellDef="let element"> {{ element.age }} </td>
                </ng-container>
        
                <ng-container matColumnDef="motherTongue">
                  <th mat-header-cell *matHeaderCellDef> Mother Tongue </th>
                  <td mat-cell *matCellDef="let element"> {{ element.motherTongue }} </td>
                </ng-container>
        
                <ng-container matColumnDef="probabilities">
                  <th mat-header-cell *matHeaderCellDef> Probabilities </th>
                  <td mat-cell *matCellDef="let element"> {{ element.probabilities }} </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        
              <div style="margin-top: 2rem;">
                <ngx-charts-pie-chart
                  *ngIf="fakeAccountProbability.length > 0"
                  [view]="[400, 400]"
                  [results]="fakeAccountProbability"
                  [legend]="true"
                  [doughnut]="true"
                  [labels]="true"
                  [explodeSlices]="false"
                  [gradient]="true">
                </ngx-charts-pie-chart>
        
                <ngx-charts-bar-vertical
                *ngIf="topicsOfInterest.length > 0"
                [view]="[600, 400]"
                [results]="topicsOfInterest"
                [scheme]="customColorScheme"
                [legend]="true"
                [xAxis]="true"
                [yAxis]="true"
                [showXAxisLabel]="true"
                [xAxisLabel]="'Topics of Interest'"
                [showYAxisLabel]="true"
                [yAxisLabel]="'Interactions'">
              </ngx-charts-bar-vertical>
              
              <ngx-charts-bar-horizontal
                *ngIf="trendingTopics.length > 0"
                [view]="[600, 400]"
                [results]="trendingTopics"
                [scheme]="trendingColorScheme"
                [legend]="true"
                [xAxis]="true"
                [yAxis]="true"
                [showXAxisLabel]="true"
                [xAxisLabel]="'Trending Topics'"
                [showYAxisLabel]="true"
                [yAxisLabel]="'Mentions'">
              </ngx-charts-bar-horizontal>
              
        </div>
    </div>
  </div>
  
