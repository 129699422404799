import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UrlDialogComponent } from '../url-dialog/url-dialog.component';

@Component({
  selector: 'app-news-analysis',
  templateUrl: './news-analysis.component.html',
  styleUrl: './news-analysis.component.scss'
})
export class NewsAnalysisComponent {
  title = 'News outlet evaluation';
  validationResult: string;
  urlInfo: {
    url: string;
    status: string;
    created_at: string;
    check_count: number;
    fakeProbability: number;
    shortInfo: string;
    message: string;
    name: string;
    established: string;
    awards: number;
    previousNames: string;
    employees: number;
    logo: string;
    politicalOrientation: string;
    biasOverYears: string;
    biasThisYear: string;
    falseReports: number;
    uncoveringReports: number;
    trustedByPercent: number;
    overAllTrustability: number; 
  } | null = null;

  constructor(public dialog: MatDialog, private http: HttpClient) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(UrlDialogComponent, {
      width: '400px',
      data: { valid: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.validateUrl(result.url);
      }
    });
  }

  validateUrl(url: string): void {
    this.http.post<any>('https://www.canpostthis.com/backend/api/validate-url', { url }).subscribe(
      response => {
        this.urlInfo = {
          url: url,
          status: response.valid ? 'Accepted' : 'Not Accepted',
          created_at: response.created_at,
          check_count: response.count,
          fakeProbability: response.fakeProbability || 0,
          shortInfo: response.shortInfo || 'No additional information available.',
          message: response.message || 'No feedback message available.',
          name: response.name || 'n/a',
          established: response.established || 'n/a',
          awards: response.awards || 0,
          previousNames: response.previousNames || 'n/a',
          employees: response.employees || 0,
          logo: response.logo || 'n/a',
          politicalOrientation: response.politicalOrientation || 'n/a',
          biasOverYears: response.biasOverYears || 'n/a',
          biasThisYear: response.biasThisYear || 'n/a',
          falseReports: response.falseReports || 0,
          uncoveringReports: response.uncoveringReports || 0,
          trustedByPercent: response.trustedByPercent || 0,
          overAllTrustability: response.trustedByPercent || 0

        };
      },
      error => {
        this.urlInfo = {
          url: url,
          status: 'Error',
          created_at: '',
          check_count: 0,
          fakeProbability: 0,
          shortInfo: 'An error occurred while validating the URL.',
          message: '',
          name: 'n/a',
          established: 'n/a',
          awards: 0,
          previousNames: 'n/a',
          employees: 0,
          logo: 'n/a',
          politicalOrientation: 'n/a',
          biasOverYears: 'n/a',
          biasThisYear: 'n/a',
          falseReports: 0,
          uncoveringReports: 0,
          trustedByPercent: 0, 
          overAllTrustability: 0
        };
      }
    );
  }

  openNonValidUrlDialog(url: string): void {
    const dialogRef = this.dialog.open(UrlDialogComponent, {
      width: '400px',
      data: { valid: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.post('/api/add-url', { url, additionalText: result.additionalText })
          .subscribe(() => {
            this.validationResult = 'URL and additional text have been stored.';
          }, error => {
            this.validationResult = 'An error occurred while storing the URL.';
          });
      }
    });
  }

}