import { Component } from '@angular/core';
import { TelegramService } from '../services/telegram.service';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss']
})
export class TelegramComponent {
  title: string = 'Identify Telegram Account, Group, Channel, or Bot';
  errorMessage: string | null = null;
  user: any = null;
  group: any = null;
  channel: any = null;
  bot: any = null;
  fakeProbability: number = 0;
  accountAge: string = '';
  registeredDate: string = '';
  location: string = 'Unknown';
  tagCloud: string[] = [];
  posts: any[] = [];
  isLoading: boolean = false;
  userInput: string = ''; // User's input for Telegram handle
  displayedInfo: any = null; // Holds the displayed Telegram information
  fallbackInfo: any = null; // Holds fallback data if the new solution fails


  constructor(private telegramService: TelegramService) {}

  fetchTelegramInfo(): void {
    if (!this.userInput.trim()) {
      this.errorMessage = 'Please enter a valid Telegram handle.';
      return;
    }

    // Clear previous error message
    this.errorMessage = '';

    // Attempt to fetch information from the Telegram API
    this.telegramService.getTelegramUserInfo(this.userInput).subscribe(
      (data) => {
        // Successful response
        this.displayedInfo = data;
        this.fallbackInfo = data; // Update fallback data with the most recent success
      },
      (error) => {
        // Handle error and fallback to previous data
        this.errorMessage = 'Failed to fetch Telegram information. Displaying previous data.';
        if (this.fallbackInfo) {
          this.displayedInfo = this.fallbackInfo;
        } else {
          this.displayedInfo = null;
        }
      }
    );
  }

  // Fetch Telegram user info
  getUserInfo(username: string): void {
    this.clearAll();
    this.isLoading = true;
    this.telegramService.getTelegramUserInfo(username).subscribe(
      data => {
        this.user = data;
        this.processAdditionalDetails(data);
        this.isLoading = false;
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram group info
  getGroupInfo(groupUsername: string): void {
    this.clearAll();
    this.isLoading = true;
    this.telegramService.getTelegramGroupInfo(groupUsername).subscribe(
      data => {
        this.group = data;
        this.isLoading = false;
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram channel info
  getChannelInfo(channelUsername: string): void {
    this.clearAll();
    this.isLoading = true;
    this.telegramService.getTelegramChannelInfo(channelUsername).subscribe(
      data => {
        this.channel = data;
        this.isLoading = false;
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram bot info
  getBotInfo(botUsername: string): void {
    this.clearAll();
    this.isLoading = true;
    this.telegramService.getTelegramBotInfo(botUsername).subscribe(
      data => {
        this.bot = data;
        this.isLoading = false;
      },
      error => this.handleError(error)
    );
  }

  // Clear all data
  clearAll(): void {
    this.user = null;
    this.group = null;
    this.channel = null;
    this.bot = null;
    this.errorMessage = null;
    this.fakeProbability = 0;
    this.accountAge = '';
    this.registeredDate = '';
    this.location = 'Unknown';
    this.tagCloud = [];
    this.posts = [];
  }

  // Process additional details for user accounts
  processAdditionalDetails(data: any): void {
    // Calculate fake probability (example logic)
    this.fakeProbability = Math.random() * 100;

    // Determine account age and registered date (example logic)
    const creationDate = new Date(data.creation_date || Date.now());
    this.registeredDate = creationDate.toLocaleDateString();
    const ageInMilliseconds = Date.now() - creationDate.getTime();
    this.accountAge = `${Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24))} days`;

    // Tag cloud and posts (example placeholders)
    this.tagCloud = ['Technology', 'Education', 'Gaming', 'Music', 'Art'];
    this.posts = [
      { description: 'Example Post 1', likes: 120, views: 1000 },
      { description: 'Example Post 2', likes: 340, views: 2300 }
    ];
  }

  // Handle errors
  handleError(error: any): void {
    this.errorMessage = 'An error occurred. Please try again later.';
    this.isLoading = false;
    console.error(error);
  }
}
