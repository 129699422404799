import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FacebookService } from '../services/facebook.service';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit {
  errorMessage: string | null = null;
  profile: any = null;
  title = 'Facebook';

  constructor(private facebookService: FacebookService) {}

  ngOnInit(): void {}

  getFacebookInfo(usernameOrUrl: string): void {
    if (!usernameOrUrl) {
      this.errorMessage = 'Username or URL is required!';
      return;
    }

    this.facebookService.getFacebookProfile(usernameOrUrl).subscribe(
      (response) => {
        console.log('Facebook API response:', response);
        if (response && response.url) {
          this.profile = response;
          this.errorMessage = null;
        } else {
          this.errorMessage = 'Facebook user data not found in the API response.';
          this.profile = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching Facebook profile: ${error.message}`;
        console.error('Facebook API error:', error);
      }
    );
  }
}
