<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>Instagram</h1>

      <div class="instagram-container">
        <h2>Instagram User Information</h2>

        <input
          type="text"
          [(ngModel)]="userInput"
          placeholder="Enter Instagram username"
          class="user-input"
        />
        <button (click)="getUserInfo(userInput)">Fetch Info</button>

        <div *ngIf="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>

        <div *ngIf="user" class="info-container">
          <h3>{{ user.full_name }} (&#64;{{ user.username }})</h3>
          <p><strong>ID:</strong> {{ user.id }}</p>
          <img *ngIf="user.profile_picture" [src]="user.profile_picture" alt="{{ user.full_name }}'s profile image">
          <p><strong>Followers:</strong> {{ user.follower_count }}</p>
          <p><strong>Following:</strong> {{ user.following_count }}</p>
          <p><strong>Bio:</strong> {{ user.biography }}</p>
          <p><strong>Location:</strong> {{ user.location }}</p>
          <p *ngIf="user.created_time">
            <strong>Account Created At:</strong> {{ user.created_time | date: 'short' }}
          </p>
          <p class="fake-probability" *ngIf="fakeProbability >= 0" style="color: red;">
            {{ probabilityMessage }}
          </p>
        </div>

        <div *ngIf="latestPosts.length > 0">
          <h2>Latest Posts</h2>
          <div *ngFor="let post of latestPosts" class="post">
            <img [src]="post.image_url" alt="Post image">
            <p>{{ post.caption }}</p>
          </div>
        </div>

        <div *ngIf="tags.length > 0">
          <h2>Tag Cloud</h2>
          <div class="tag-cloud">
            <span *ngFor="let tag of tags" class="tag">{{ tag }}</span>
          </div>
        </div>

        <div *ngIf="!user && !errorMessage" class="no-data">
          No data to display.
        </div>

        <h2>Fetch Community Data by CID</h2>
        <div class="fetch-section">
          <input
            type="text"
            placeholder="Enter Instagram CID"
            #cidInput
            class="cid-input"
          />
          <button (click)="getCommunityByCid(cidInput.value)">Fetch Community Info</button>
        </div>

        <div *ngIf="communityData" class="info-container">
          <h3>Community Data</h3>
          <pre class="community-json">{{ communityData | json }}</pre>
        </div>
      </div>
    </div>
  </div>
</div>
