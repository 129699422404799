// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  facebookAppId: '314930319788683',
  consumerKey: 'ccmFatpEuCsd4xbFfMNk3YoU7',
  consumerSecret: 'AI05zSWR44uwIGKSJ7UgvvrjduF2xAkxksOQmiDB9U5aV4IoL2',
  clientId: '780134460535976',
  apiUrl: 'https://www.canpostthis.com/backend/api',
  redirectUri: 'https://localhost:4200'
};
