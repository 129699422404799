import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

interface InstagramUser {
  id: string;
  username: string;
  full_name: string;
  profile_picture: string;
  follower_count: number;
  following_count: number;
  biography: string;
  location: string;
  created_time: number; // Unix timestamp
}

interface Post {
  id: string;
  caption: string;
  image_url: string;
  timestamp: number; // Unix timestamp
}

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})

export class InstagramComponent implements OnInit {
  user: InstagramUser | null = null;
  errorMessage: string | null = null;
  fakeProbability: number = 0;
  probabilityMessage: string = '';
  latestPosts: Post[] = [];
  tags: string[] = [];
  userInput: string = '';
  communityData: any = null;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    this.authService.getInstagramProfile(username).subscribe(
      data => {
        this.user = data.data;
        this.errorMessage = null;
        this.calculateFakeProbability();
        this.fetchLatestPosts();
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = 'An error occurred. Please try again later.';
      }
    );
  }

  getCommunityByCid(cid: string): void {
    this.authService.getInstagramCommunityByCid(cid).subscribe(
      (data) => {
        this.communityData = data;
        this.errorMessage = null;
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = 'An error occurred while fetching community info.';
        console.error('Error:', error);
      }
    );
  }
  
  fetchLatestPosts(): void {
    if (this.user) {
      this.authService.getLatestInstagramPosts(this.user.username).subscribe(
        (posts: Post[]) => {
          this.latestPosts = posts;
          this.extractTags();
        },
        (error: HttpErrorResponse) => {
          console.error('Failed to fetch latest posts:', error);
        }
      );
    }
  }

  extractTags(): void {
    const allTags = this.latestPosts.reduce((tags, post) => {
      const postTags = post.caption.match(/#\w+/g) || [];
      return tags.concat(postTags);
    }, [] as string[]);
  }

  calculateFakeProbability(): void {
    let probability = 0;
    let usedCriteria = '';

    if (this.user) {
      const username = this.user.username;
      const numberCount = (username.match(/\d/g) || []).length;
      if (numberCount > 4) {
        probability += 50;
        usedCriteria += 'Username contains more than four numbers. ';
      }

      if (this.user.created_time) {
        const createdAt = new Date(this.user.created_time * 1000);
        const now = new Date();
        const ageInDays = Math.floor((now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24));
        if (ageInDays < 365) {
          probability += 50;
          usedCriteria += 'Account age is less than 1 year. ';
        }
      } else {
        usedCriteria += 'Account age is not available. ';
      }

      this.fakeProbability = probability;
      this.probabilityMessage = `Probability of ${this.user.username} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
    }
  }
}
