import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YouTubeService {
  private storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount';
  private apiUrl = 'https://youtube-v2.p.rapidapi.com/channel/details';
  private headers = new HttpHeaders({
    'x-rapidapi-host': 'youtube-v2.p.rapidapi.com',
    'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e',
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  /**
   * Fetches channel details for a given channel ID and stores the account in the database.
   * @param channelId - The YouTube channel ID.
   */
  getChannelDetails(channelId: string): Observable<any> {
    const params = new HttpParams().set('channel_id', channelId);
    return this.http.get<any>(this.apiUrl, { headers: this.headers, params }).pipe(
      tap(channelDetails => {
        // Store the account in the database after fetching details
        const account = {
          service: 'youtube',
          data: channelDetails
        };
        this.storeAccountInDatabase(account);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  /**
   * Fetches additional videos using a continuation token.
   * @param continuationToken - Token for fetching the next batch of videos.
   */
  getMoreVideos(continuationToken: string): Observable<any> {
    const params = new HttpParams().set('continuation_token', continuationToken);
    return this.http.get<any>(`${this.apiUrl}/more-videos`, { headers: this.headers, params }).pipe(
      catchError((error) => this.handleError(error))
    );
  }

  /**
   * Stores a YouTube account in the database.
   * @param account - Account data to be stored.
   */
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account, { headers: this.headers }).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }

  /**
   * Fetches video suggestions based on an audio ID and continuation token.
   * @param audioId - The audio ID.
   * @param continuationToken - Token for fetching additional suggestions.
   */
  getSuggestions(audioId: string, continuationToken: string): Observable<any> {
    const params = new HttpParams()
      .set('audio_id', audioId)
      .set('continuation_token', continuationToken);

    return this.http.get<any>(this.apiUrl, { headers: this.headers, params }).pipe(
      catchError((error) => this.handleError(error))
    );
  }

  /**
   * Error handler for HTTP requests.
   * @param error - Error object returned from the HTTP request.
   */
  private handleError(error: any): Observable<never> {
    console.error('API call failed', error);
    return throwError(() => new Error(`Something went wrong: ${error.message}`));
  }
}
