import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'register-cmp',
  templateUrl: 'register.component.html',
})
export class RegisterComponent implements OnInit {
  registerForm: UntypedFormGroup;
  msg = '';
  showSuccess = false; 

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthService
  ) {
    this.registerForm = this.formBuilder.group({
      inputEmail: ['', [Validators.required, Validators.email]],  
      inputPassword: ['', [Validators.required]],
    });
  }

  ngOnInit() {}

  register(): void {
    this.authenticationService
      .register(this.f.inputEmail.value, this.f.inputPassword.value)  // Updated to inputEmail
      .pipe(first())
      .subscribe(
        user => {
          console.log('====>>', user);
          this.showSuccess = true;  // Set showSuccess to true on successful registration
          this.router.navigate(['/login']);
        },
        error => {
          console.log('====>>', error.error);
          this.msg = error.error.message || 'Failed to register';
        }
      );
  }

  get f() {
    return this.registerForm.controls;
  }
}
