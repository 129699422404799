import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'; // Import tap for side-effect handling

@Injectable({
  providedIn: 'root'
})
export class SnapchatService {

  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your actual key
  private apiHost = 'snapchat3.p.rapidapi.com';
  private readonly storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount'; // Endpoint to store account data

  constructor(private http: HttpClient) {}

  // Method to get Snapchat profile information by username
  getSnapchatProfile(username: string): Observable<any> {
    const url = `https://${this.apiHost}/getProfile?username=${username}`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json'
    });
    
    return this.http.get(url, { headers }).pipe(
      tap((profileData) => {
        // Store the profile data in the database after fetching
        const account = {
          service: 'snapchat',
          data: profileData,
        };
        this.storeAccountInDatabase(account);
      })
    );
  }

  // Method to store account in the database
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }
}
