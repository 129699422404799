import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.apiUrl;
  private clientId = environment.clientId;
  private redirectUri = environment.redirectUri;


  constructor(private http: HttpClient) {}

  initiateAuth() {
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=user_profile,user_media`;
    window.location.href = authUrl;
  }

  exchangeCode(code: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/exchange-code`, { code });
  }

  getInstagramProfile(username: string): Observable<any> {
    const apiUrl = `https://instagram-scraper-api2.p.rapidapi.com/v1/info?username_or_id_or_url=${username}`;
    return this.http.get(apiUrl, {
      headers: {
        'x-rapidapi-host': 'instagram-scraper-api2.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e' // Replace with your RapidAPI key
      }
    });
  }
  // Method to get latest Instagram posts for a user
  getLatestInstagramPosts(username: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/instagram-posts?username=${username}`);
  }
  
  setSession(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  logout() {
    localStorage.removeItem('access_token');
  }

  login(email: string, password: string): Observable<any> {
    const headers = this.getHeaders(); // Add headers if tokens are needed
    return this.http.post(
      `${this.apiUrl}/login`, 
      { inputEmail: email, inputPassword: password }, 
      { withCredentials: true } // Ensure credentials are included
    );
  }
  
  register(email: string, password: string): Observable<any> {
    const headers = this.getHeaders(); // Add headers if tokens are needed
    return this.http.post(
      `${this.apiUrl}/register`, 
      { inputEmail: email, inputPassword: password }, 
      { withCredentials: true } // Ensure credentials are included
    );
  }
  

  getInstagramCommunityByUrl(url: string): Observable<any> {
    const encodedUrl = encodeURIComponent(url);
    return this.http.get(`${this.apiUrl}?url=${encodedUrl}`, {
    });
  }

  getInstagramCommunityByCid(cid: string): Observable<any> {
    return this.http.get(`${this.apiUrl}?cid=${encodeURIComponent(cid)}`, {    });
  }
}
