import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TruthSocialService } from '../services/truth-social.service';
import { User } from '../interfaces/user.interface';

@Component({
  selector: 'app-truth-social',
  templateUrl: './truth-social.component.html',
  styleUrls: ['./truth-social.component.scss'],
})
export class TruthSocialComponent implements OnInit {
  title: string = 'Truth Social';
  userInfo: User | null = null;
  posts: any[] = [];
  errorMessage: string | null = null;
  tagCloud: string[] = [];
  registeredDate: string = '';
  accountAge: string = '';
  fakeProbability: number = 0;
  probabilityMessage: string = '';


  constructor(private truthSocialService: TruthSocialService) {
    this.userInfo = {
      header: 'Welcome to Truth Social',
      username: 'ExampleUser',
      note: 'This is an example user profile.',
      createdAt: new Date().toISOString(),
      lastStatusAt: new Date().toISOString(),
      followerCount: 1234,
      statusesCount: 5678,
      website: 'https://example.com',
      createdTime: Date.now() // Example timestamp
    };
  }

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    if (!username) {
      this.errorMessage = 'Username is required!';
      return;
    }

    this.truthSocialService.getUserProfile(username).subscribe(
      (response) => {
        this.errorMessage = null;

        if (response) {
          this.userInfo = {
            header: response.header || '',
            username: response.username || '',
            note: response.note || '',
            createdAt: response.created_at || '',
            lastStatusAt: response.last_status_at || '',
            followerCount: response.followers_count || 0,
            statusesCount: response.statuses_count || 0,
            website: response.website || '',
            createdTime: response.website || ''
          };

          if (response.id) {
            this.getUserPosts(response.id);
          }

          this.calculateAccountDetails(response.created_at);
          this.calculateFakeProbability();
        } else {
          this.errorMessage = 'User information not found.';
          this.userInfo = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred: ${error.message}`;
        console.error('API error:', error);
      }
    );
  }

  getUserPosts(userId: string): void {
    this.truthSocialService.getUserPosts(userId).subscribe(
      (data) => {
        this.posts = data.posts || [];
        this.generateTagCloud();
      },
      (error: HttpErrorResponse) => {
        console.error('Error fetching user posts:', error);
        this.posts = [];
      }
    );
  }

  calculateAccountDetails(createdAt: string): void {
    if (createdAt) {
      const createDate = new Date(createdAt);
      this.registeredDate = createDate.toDateString();
      const ageInMs = new Date().getTime() - createDate.getTime();
      this.accountAge = `${Math.floor(ageInMs / (365 * 24 * 60 * 60 * 1000))} years`;
    }
  }

  calculateFakeProbability(): void {
    if (!this.userInfo) return;

    let probability = 0;
    let usedCriteria = '';

    if (this.userInfo.username && (this.userInfo.username.match(/\d/g) || []).length > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }

    if (this.userInfo.createdAt) {
      const accountAgeMs = new Date().getTime() - new Date(this.userInfo.createdAt).getTime();
      if (accountAgeMs < 365 * 24 * 60 * 60 * 1000) {
        probability += 50;
        usedCriteria += 'Account age is less than 1 year. ';
      }
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.userInfo.username} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
  }

  generateTagCloud(): void {
    const keywords: { [key: string]: number } = {};

    this.posts.forEach((post) => {
      const words = post.content?.split(' ') || [];
      words.forEach((word: string) => {
        keywords[word] = (keywords[word] || 0) + 1;
      });
    });

    this.tagCloud = Object.keys(keywords)
      .sort((a, b) => keywords[b] - keywords[a])
      .slice(0, 10);
  }
}
