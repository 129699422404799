import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your key
  private apiHost = 'find-fb-account-ig.p.rapidapi.com';
  private storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount';

  constructor(private http: HttpClient) {}

  getFacebookProfile(usernameOrUrl: string): Observable<any> {
    const url = `https://${this.apiHost}/`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new URLSearchParams({
      username_or_url: usernameOrUrl,
      data: 'fb_profile'
    });

    return this.http.post(url, body.toString(), { headers }).pipe(
      tap((response) => {
        // Store the queried account in the database
        this.storeAccountInDatabase({ service: 'facebook', data: response });
      })
    );
  }

  private storeAccountInDatabase(account: { service: string; data: any }): void {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.post(this.storeAccountApi, account, { headers }).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }
}



