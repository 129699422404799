import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TruthSocialService {
  private readonly baseUrl = 'https://truth-social-api.p.rapidapi.com';
  private readonly headers = new HttpHeaders({
    'x-rapidapi-host': 'truth-social-api.p.rapidapi.com',
    'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e',
  });
  private readonly storeAccountApi = 'https://www.canpostthis.com/backend/api/storeAccount';

  constructor(private http: HttpClient) {}

  /**
   * Fetches user profile for a given username and stores the account in the database.
   * @param username - The Truth Social username.
   */
  getUserProfile(username: string): Observable<any> {
    const url = `${this.baseUrl}/users/${username}`;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      tap((userProfile) => {
        // Store the user profile in the database after fetching the data
        const account = {
          service: 'truthsocial',
          data: userProfile,
        };
        this.storeAccountInDatabase(account);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Fetches user posts for a given user ID and stores the account in the database.
   * @param userId - The Truth Social user ID.
   */
  getUserPosts(userId: string): Observable<any> {
    const url = `${this.baseUrl}/users/${userId}/posts`;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      tap((userPosts) => {
        // Store the user posts in the database after fetching the data
        const account = {
          service: 'truthsocial',
          data: userPosts,
        };
        this.storeAccountInDatabase(account);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Stores a Truth Social account in the database.
   * @param account - Account data to be stored.
   */
  private storeAccountInDatabase(account: { service: string; data: any }): void {
    this.http.post(this.storeAccountApi, account, { headers: this.headers }).subscribe({
      next: () => console.log(`Account stored: ${JSON.stringify(account)}`),
      error: (err) => console.error(`Error storing account: ${err.message}`),
    });
  }

  /**
   * Error handler for HTTP requests.
   * @param error - Error object returned from the HTTP request.
   */
  private handleError(error: any): Observable<never> {
    console.error('API error:', error);
    return throwError(() => new Error('Failed to fetch data from Truth Social API.'));
  }
}
