
import { YouTubeService } from '../services/youtube.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent {

  channelDetails?: {
    title: string;
    avatar: { url: string }[];
    description: string;
    subscriber_count: number;
    links: { url: string; label: string }[];
    banner: { url: string }[];
  };
  queryInputValue: string = ''; 
  continuationToken: string = ''; 
  suggestions: any[] = []; 
  errorMessage: string = ''; 
  title: string = 'Youtube';    
  channelData: any = null;  
  searchQuery: string = '';
  channelInfo: any = null;
  videos: any[] = [];

  
  constructor(private youtubeService: YouTubeService) {}

  getSuggestions(): void {
    console.log('Fetching suggestions for:', this.queryInputValue, this.continuationToken);
    
    this.youtubeService.getSuggestions(this.queryInputValue, this.continuationToken).subscribe(
      (response) => {
        console.log('API Response:', response);  // Log the API response
  
        if (response && response.data) {
          this.suggestions = response.data;  // Assuming response.data contains the suggestions
          console.log('Suggestions:', this.suggestions);  // Log the suggestions
        } else {
          this.errorMessage = 'No suggestions found or invalid response.';
          console.error(this.errorMessage);  // Log error if no data
        }
      },
      (error) => {
        this.errorMessage = 'An error occurred while fetching suggestions.';
        console.error(this.errorMessage, error);  // Log the error if API call fails
      }

    );
  }
  searchChannel(): void {
    if (!this.searchQuery.trim()) return;

    this.youtubeService.getChannelDetails(this.searchQuery).subscribe(
      (response) => {
        this.channelDetails = response;
        console.log('Channel Details:', this.channelDetails);
        if (response.links && Array.isArray(response.links)) {
          this.channelDetails.links = response.links;
        }
      },
      (error) => {
        console.error('Error fetching channel details:', error);
      }
    );
  }
  getChannelDetails(channelId: string): void {
    if (channelId) {
      this.youtubeService.getChannelDetails(channelId).subscribe(
        (data: any) => {
          this.channelData = data;  // Update the channel data object
        },
        (error) => {
          console.error('Error fetching channel details:', error);
        }
      );
    }
  }

  loadMoreVideos(): void {
    this.youtubeService.getMoreVideos(this.continuationToken).subscribe(
      (response) => {
        this.videos = [...this.videos, ...response.videos];
        this.continuationToken = response.continuationToken;
      },
      (error) => {
        console.error('Error fetching more videos:', error);
      }
    );
  }

  
}
